import React from 'react'
import '../css/staff.css';
import '../css/gallery.css';
import coming_soon from '../images/coming_soon.jpg';
import gallery1 from '../images/gallery/gallery1.jpg'
import gallery2 from '../images/gallery/gallery2.jpg'
import gallery3 from '../images/gallery/gallery3.jpg'
import gallery4 from '../images/gallery/gallery4.jpg'
import gallery5 from '../images/gallery/gallery5.jpg'
import gallery6 from '../images/gallery/gallery6.jpg'
import boye1 from '../images/gallery/Boye (1).jpg'
import boye2 from '../images/gallery/Boye (2).jpg'
import boye3 from '../images/gallery/Boye (3).jpg'
import boye4 from '../images/gallery/Boye (4).jpg'
import boye5 from '../images/gallery/Boye (5).jpg'
import { Helmet } from 'react-helmet';


export default function Gallery() {
    return (
        <div className="gallery-content">
            <Helmet>
                <title>In Motion Dance | Gallery</title>
            </Helmet>
            <h1 className="staff-topText"> Gallery! </h1>
            <div className='gallery-grid'>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={boye1} alt='boye1' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Choreographing and dancing for an Alex Boye music video</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={boye2} alt='boye2' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Continuing to work hard.</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={boye3} alt='boye3' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Filming the Alex Boye music video</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={boye4} alt='boye4' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Filming cont...</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={boye5} alt='boye5' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>What an amazing experience we will all treasure!</p>
                </div>
                </div>
            </div>
            
            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={gallery1} alt='costume-week1' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>A few of our classes on costume week.</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={gallery2} alt='costume-week2' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Costume week cont.</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={gallery3} alt='camp-williams1' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Elite company performing Thriller at Camp Williams Military base.</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
            <div class="gallery-table">
                <div class="gallery-image">
                <img src={gallery4} alt='camp-williams2' width='80%'/>
                </div>
                <div class="gallery-description">
                <p>Camp Williams</p>
                </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
                <div class="gallery-table">
                    <div class="gallery-image">
                    <img src={gallery5} alt='camp-williams3' width='80%'/>
                    </div>
                    <div class="gallery-description">
                    <p>Camp Williams</p>
                    </div>
                </div>
            </div>

            {/* Picture Div */}
            <div class="gallery-container">
                <div class="gallery-table">
                    <div class="gallery-image">
                    <img src={gallery6} alt='camp-williams4' width='80%'/>
                    </div>
                    <div class="gallery-description">
                    <p>Camp Williams</p>
                    </div>
                </div>
            </div>

            </div> {/* Grad Div */}

            {/* Content Div End*/}
        </div> 
            
    )
}
